import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { numberToCurrency } from 'utilities';
import { DATE_IN_BETWEEN } from 'utilities/helpers';
import { FormInput, ActivityCard, ActivityModal } from 'components';

class BookingActivity extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modalOpen: false,
      selected: this.anyOptionSelected(),
    };
    this.onOptionChange = this.onOptionChange.bind(this);
    this.onTravellerChange = this.onTravellerChange.bind(this);
    this.handleModalOpen = this.handleModalOpen.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
  }

  /**
   * On option selected
   * @param e
   */
  onOptionChange(e, price) {
    const {
      activity,
      travellers,
      day,
      update,
    } = this.props;
    const CHECKED = e.currentTarget.checked;
    const VALUE = e.currentTarget.value; // option ID
    this.setState({
      selected: CHECKED, // always set to selected
    });

    const UPDATE_DATA = [];
    travellers.forEach((traveller) => {
      UPDATE_DATA.push({
        data: {
          addOnID: activity.api_id,
          optionID: VALUE,
          dayID: day.api_id,
          optionPrice: price,
          travellerID: traveller.id,
        },
        destroy: !CHECKED,
      });
    });
    // bulk update add-ons
    update(UPDATE_DATA);
  }

  /**
   * When a traveller option changes
   * @param e
   * @param optionID
   */
  onTravellerChange(e, optionID) {
    const { activity, day, update } = this.props;
    const CHECKED = e.currentTarget.checked;
    const TRAVELLER_ID = e.currentTarget.value;
    const DATA = {
      addOnID: activity.api_id,
      optionID,
      dayID: day.api_id,
      travellerID: TRAVELLER_ID,
    };

    if (CHECKED) {
      update([{
        data: DATA,
        destroy: false,
      }]);
    } else {
      update([{
        data: DATA,
        destroy: true,
      }]);
    }
  }

  getPricing(option, day) {
    const { booking } = this.props;
    const options = [];
    if (option.seasonal_pricings && option.seasonal_pricings.length > 0) {
      option.seasonal_pricings.map((seasonalPrice) => {// eslint-disable-line
        if (DATE_IN_BETWEEN(booking.currentBooking.departure_date, day.ordinal_number, seasonalPrice.available_from, seasonalPrice.available_to)) {
          options.push(seasonalPrice.price);
        }
      });
    }
    if (options.length === 0) {
      options.push(option.price);
    }
    return options;
  }

  /**
   * Is any option selected for this activity + day
   * @returns {*}
   */
  anyOptionSelected() {
    const {
      activity,
      bookingAddOns,
      day,
    } = this.props;
    const MATCHES = bookingAddOns.find(addOn => addOn.day_id === day.api_id
      && addOn.db_addon_id === activity.api_id
      && !addOn._destroy);  // eslint-disable-line
    return !!MATCHES;
  }

  /**
   * Find traveller and option in booking add-ons
   * @param traveller
   * @param optionID
   * @param dayID
   * @returns {boolean}
   */
  travellerSelected(traveller, optionID, dayID) {
    const { bookingAddOns } = this.props;
    const MATCH = bookingAddOns.find(addOn => addOn.day_id === dayID
      && !addOn._destroy // eslint-disable-line
      && addOn.traveller_id === traveller.id
      && addOn.option_id === optionID);
    return !!MATCH;
  }

  /**
   * Set traveller option to disabled to prevent user selecting more than
   * one add-on per day
   * @param traveller
   * @param optionID
   * @param dayID
   * @returns {boolean}
   */
  travellerDisabled(traveller, optionID, dayID) {
    const { bookingAddOns } = this.props;
    const SAME_DAY_BOOKING = bookingAddOns
      .find(addOn => !addOn._destroy // eslint-disable-line
        && addOn.day_id === dayID
        && addOn.option_id !== optionID
        && addOn.traveller_id === traveller.id);
    return !!SAME_DAY_BOOKING;
  }

  handleModalOpen() {
    this.setState({
      modalOpen: true,
    });
  }

  handleModalClose() {
    this.setState({
      modalOpen: false,
    });
  }

  render() {
    const {
      activity, travellers, day, images, options, isDisabled,
    } = this.props;
    // const {
    //   activity, travellers, day, images,
    // } = this.props;
    const { selected, modalOpen } = this.state;
    return (
      <div className="c-booking-activity">
        {/* eslint-disable-next-line */}
        <div onClick={(e) => {
          e.preventDefault();
          this.handleModalOpen();
        }}
        >
          <ActivityCard
            showFavourite={false}
            showPrice={false}
            data={{
              ...activity,
              destination_name: `${activity.destination_name} - Day ${day.ordinal_number}`,
            }}
          />
        </div>
        <ActivityModal activity={activity} images={images} open={modalOpen} handleClose={this.handleModalClose} />
        <form action="">
          <div className={`l-form-option-list ${selected ? 'is-selected' : ''}`}>
            {options.filter(option => option.hidden === false).filter(option => option.archived === false).map(option => (
              this.getPricing(option, day).map(seasonalPrice => (
                <div className="l-form-option-list__group" key={option.api_id}>
                  <div className="l-form-option-list__option">
                    {/* <FormInput
                      id={`${day.api_id}-${option.api_id}`}
                      name={option.name}
                      type="checkbox"
                      modifier="c-form-control--checkbox"
                      value={option.api_id}
                      onChange={(e) => {
                        this.onOptionChange(e, seasonalPrice);
                      }}
                      attrs={{
                        defaultChecked: selected,
                        disabled: isDisabled,
                      }}
                    /> */}
                    <label
                      htmlFor={`${day.api_id}-${option.api_id}`}
                    >
                      {option.name} - {numberToCurrency(seasonalPrice)}
                    </label>
                  </div>
                  {/* <div className="l-form-option-list__sub-options">
                    {travellers.map(traveller => (
                      <div className="l-form-option-list__sub-option" key={traveller.id}>
                        <FormInput
                          id={`${option.api_id}-${traveller.id}-${day.api_id}`}
                          name={traveller.id}
                          type="checkbox"
                          modifier="c-form-control--checkbox"
                          value={traveller.id}
                          attrs={{
                            checked: this.travellerSelected(traveller, option.api_id, day.api_id),
                            disabled: this.travellerDisabled(traveller, option.api_id, day.api_id) || isDisabled,
                          }}
                          onChange={(e) => {
                            this.onTravellerChange(e, option.api_id);
                          }}
                        />
                        <label
                          htmlFor={`${option.api_id}-${traveller.id}-${day.api_id}`}
                        >
                          {traveller.first_name} {traveller.last_name}
                        </label>
                      </div>
                    ))}
                  </div> */}
                </div>
              ))))}
          </div>
        </form>
      </div>
    );
  }
}

BookingActivity.propTypes = {
  activity: PropTypes.object.isRequired,
  images: PropTypes.any.isRequired,
  travellers: PropTypes.array.isRequired,
  day: PropTypes.any.isRequired,
  update: PropTypes.func.isRequired,
  bookingAddOns: PropTypes.array.isRequired,
  options: PropTypes.array.isRequired,
  booking: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool,
};

BookingActivity.defaultProps = {
  isDisabled: false,
};

export default BookingActivity;
